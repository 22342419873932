/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData } from '../Common/components/EmptyData';
import { useState } from 'react';
import { clientScreenHandle } from './state/actions';
import { FaRegFilePdf } from "react-icons/fa";
import styles from "./styles/ClientScreen.module.css"
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
const DocumentSign = () => {
  const { clientScreenData,setLoading, clientScreenDataFromApi } = useSelector((state) => state.clientScreen);
  const [signedDoc, setSignedDoc] = useState([]);
  const [unSignedDoc, setUnSignedDoc] = useState([]);
  const { getUserDetailsData } = useSelector((state) => state.account);
  const location = useLocation();
  const query = new URLSearchParams(window.location?.search);
  const documentId = query.get("documentId");
  
  const dispatch = useDispatch();
  const convertTimestampToMMDDYYYY = (timestamp) => {
    let date = moment(timestamp).format("MM/DD/YYYY");
    return date;
  }
  const convertTimestampTotime = (timestamp) => {
    const currentTime = moment(timestamp).format('h:mm A');
    return currentTime;
  }

  useEffect(() => {
    let data = {
      role: getUserDetailsData?.openFor,
      _id: getUserDetailsData?._id,
      key: getUserDetailsData?.key,
      ertsId:getUserDetailsData?.ertsId,
      offerId : getUserDetailsData?.offerId,
      thirdpartyIdDoc:getUserDetailsData?.thirdpartyId || getUserDetailsData?.thirdpartyIdDoc
    };
    dispatch(clientScreenHandle.ClientScreenData( data));
  }, []);

  useEffect(()=>{
    let signed = []
    let unSigned = [];
    let documentIdFound = false;

    clientScreenData?.forEach((ele,index) =>{
      
      if (ele?.documentId && documentId && ele.documentId.toString() === documentId.toString()) {
        documentIdFound = true;
      }
      if((ele?.signedDetail?.signed === false) || ((ele?.signedObjectionDetail?.signPermissionGranted === true) && (ele?.signedObjectionDetail?.signed === false))){
        const timestamp = ele?.sentDateMiliBuyer || ele?.sentDateMiliSeller;
        const formattedDate = convertTimestampToMMDDYYYY(timestamp);
        const currentTime=convertTimestampTotime(timestamp)
        ele.timeStamp =formattedDate + " " + currentTime;
        unSigned.push(ele);
      }
      else{
        const timestamp = ele?.signedObjectionDetail?.timeStamp || ele?.signedDetail?.timeStamp;
        const formattedDate = convertTimestampToMMDDYYYY(timestamp);
        const currentTime=convertTimestampTotime(timestamp)
        ele.timeStamp =formattedDate + " " + currentTime;
        signed.push(ele);
      }
    })
   
    if (!documentIdFound && clientScreenDataFromApi && documentId) {
      dispatch(clientScreenHandle.clientScreenDeletedDocData({ documentId }));
    }
    setSignedDoc(signed);
    setUnSignedDoc(unSigned);
  }, [clientScreenData])
  
  const arrowContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };


  const handleShortening = (ele) => {
    let buildData = {
      builtForId: getUserDetailsData?._id,
      key: getUserDetailsData?.key,
      offerId : getUserDetailsData?.offerId,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor:ele?.signedDetail?.signed && ele?.signedObjectionDetail?.signPermissionGranted && !ele?.signedObjectionDetail?.signed && getUserDetailsData?.openFor==="BUYER" ? "BUYER_WITHDRAW" : getUserDetailsData?.openFor,
      documentId:ele?.documentId,
      contractId:ele?.contractId,
      contractType: ele?.contractType,
      token: localStorage.getItem("clientAuth"),
    };
    dispatch(clientScreenHandle.DraftDocumentData(buildData, location?.pathname));
  };

 const handleOPenDocs=(ele)=>{
  let fileUrl =  ele?.resureSignedPdfLink ? ele?.resureSignedPdfLink : ele?.pdfLink 
  window.open(fileUrl, "_blank").focus();
}
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 10.1rem)",
       }}
    >
      <div
      className={styles.documentsigning}
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: " #0078d4",
          color: "white",
          fontSize: "15px",
         alignItems:"center",
          height:"8%",padding:"5px"
        }}
      >
        <div className={styles.action}>Action</div>
        <div className={styles.document}>Document</div>
        <div className={styles.description}>Time/Date</div>
      </div>
      <div style={{ width: "100%", height: "92%" }}>
      {setLoading?<div style={{ width: "100%", fontSize: "15px", height:"50%", color: "black",textAlign:"center",paddingTop:"10%"}}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px",  height: "50%", color: "black", overflowY: "auto",}}>
          {(unSignedDoc && unSignedDoc.length) ? unSignedDoc?.map((ele,index) =>
            <div
              key={index}
              className={styles.hoverClass}
              onClick={() => ele?.ispdfClone || ele?.isPdf ? handleOPenDocs(ele) : handleShortening(ele)}
            >
              <div style={{ width: "20.33%", fontSize: "15px", display: "flex", justifyContent: "center" }}>
                <div style={arrowContainerStyle}>
                  <div style={{ width: "40px", backgroundColor: ele?.ispdfClone || ele?.isPdf || !ele?.isSignatureRequired ? "lightgray" : "yellow", height: "20px", display: "flex", alignItems: "center" }}>
                    <text style={{ color: "black", fontSize: "12px", width: "100%", textAlign: "center", fontWeight: "bold" }}>{ele?.isSignatureRequired ? "Sign" : "View"}</text>
                  </div>
                  <div className={ele?.ispdfClone || ele?.isPdf || !ele?.isSignatureRequired ? styles.arrowStyle2 : styles.arrowStyle}></div>
                </div>
              </div>
              <div style={{ width: "36.33%", textAlign: "center" }}>
                <div className={styles.contractName}>{ele?.contractName}<text style={{ marginLeft: "5px", color: "red" }}>{ele?.ispdfClone ? <FaRegFilePdf /> : ""}</text></div>
              </div>
              <div style={{ width: "40.33%", textAlign: "center" }} className={styles.timestamp}>{ele?.timeStamp}</div>
            </div>
          ):<EmptyData/>}
        </div>}
        <hr style={{ color: "#0078d4" }} />
       
        {setLoading?<div style={{ width: "100%", fontSize: "15px", height: "47%", color: "black",textAlign:"center",paddingTop:"10%"}}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px", height: "47%", color: "black", overflowY: "auto",}}>
          {(signedDoc && signedDoc.length) ? signedDoc?.map((ele,index) =>
              <div
                className={styles.hoverClass}
                onClick={() => ele?.ispdfClone||ele?.isPdf? handleOPenDocs(ele):handleShortening(ele)}
               >
                <div style={{ width: "20.33%", fontSize: "20px", color: "green" , textAlign:"center"}}>
                  <CheckCircleOutlined />
                </div>
                <div className={styles.contractName} style={{width: "47.33%"}}>{ele?.contractName}{ele?.ispdfClone?<FaRegFilePdf/>:""}</div>
                <div style={{ width: "29.33%", textAlign: "left" }} className={styles.timestamp}>{ele?.timeStamp}</div>
              </div>
          ):<EmptyData/>}
        </div>}
      </div>
    </div>
  );
}

export { DocumentSign };
