import { Button, Carousel, Dropdown, Image, Menu, Modal } from "antd";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import housePlaceHolder from "../../Assets/imagePlaceholder.webp";
import {
  LeftOutlined,
  RightOutlined,
  MoreOutlined,
  DeleteTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { PropertyHandlers } from "../state/actions";
import { DraggerComp } from "../../Common/components/DraggerComp";
import styles from "../styles/profileDetails.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { checkIfDisabled, disabledTransactionText } from "../../Common/commondata/commonData";

const PropertyImgs = ({ currentlySelectedData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();
  const uploadFile = useRef("");
  const history = useHistory();
  const {pathname} = useLocation();
  const { confirm } = Modal;

  const redirectFunc = () => {
    history.push({
      pathname,
      state: {
        category: ( (currentlySelectedData?.isProperty || currentlySelectedData?.isErts) ? "SELLER" : "BUYER" ),
        categoryId: ( (currentlySelectedData?.isProperty || currentlySelectedData?.isErts) ? currentlySelectedData.propertyId : currentlySelectedData.offerId )
      }
    })
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const uploadTemp = (setShowInputFileName, fileNametext, setFileNametext) => {
    if (currentlySelectedData?.propertyId)
      uploadFile.current.append(
        "propertyId",
        currentlySelectedData?.propertyId,
      );
    if (currentlySelectedData?.thirdpartyId)
      uploadFile.current.append(
        "thirdpartyId",
        currentlySelectedData?.thirdpartyId,
      );
    dispatch(PropertyHandlers.postPropertyImage(uploadFile.current, redirectFunc));
    setFileNametext("");
  };

  const handleDeleteConfirm = (ImageId) => {
    confirm({
      title: `Are you sure you want to delete this Image?`,
      content: "",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(PropertyHandlers.deletePropertyImage(ImageId, redirectFunc));
      },
      onCancel() {},
    });
  };

  const MenuItem = (imageId) => {
    return (
      <Menu size="large">
        <Menu.Item
          key="draft"
          onClick={() => handleDeleteConfirm(imageId?.item)}
        >
          <DeleteTwoTone
            style={{
              fontSize: 16,
            }}
          />
          <span style={{ color: "04396B" }}> Delete Image</span>
        </Menu.Item>
      </Menu>
    );
  };

  const checkForDisable = checkIfDisabled({openFor: (currentlySelectedData?.isProperty || currentlySelectedData?.isErts) ? "SELLER" : "BUYER", actualPropertyStatus: currentlySelectedData?.actualPropertyStatus, buyingStatus: currentlySelectedData?.actualOfferStatus, offerStatus: currentlySelectedData?.status});

  return (
    <div>
      <Modal
        style={{ minHeight: "400px" }}
        title={
          <span
            style={{ display: "flex", justifyContent: "center", color: "grey" }}
          >
            UPLOAD IMAGE
          </span>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DraggerComp
            uploadFile={uploadFile}
            uploadTemp={uploadTemp}
            handleCancel={handleCancel}
            toggle={true}
            accept="image/*"
          />
        </div>
      </Modal>
      <div
        className="modalContent"
        style={{
          fontWeight: "bold",
          width: "200px",
          height:"103px",
          textAlign: "left",
          color: "rgb(76,170,255)",
          fontSize: "19px",
        }}
      >
        {currentlySelectedData?.images?.length > 0 ? (
          <Carousel
            draggable
            dots={true}
            dotPosition="bottom"
            effect="fade"
            ref={ref}
          >
            {currentlySelectedData?.images?.map((el, index) => (
              <div key={index}>
                <Image
                  width={"100%"}
                  height={100}
                  src={el?.imageUrl}
                  alt="home"
                  style={{ objectFit: "cover" }}
                />
                <div style={{ position: "absolute", top: "35%", left: "5px" }}>
                  {index !== 0 && (
                    <Button
                      type="ghost"
                      size="small"
                      style={{
                        border: "none",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        borderRadius: "5px",
                      }}
                      onClick={() => ref.current.prev()}
                      icon={
                        <LeftOutlined
                          className={styles.hoverClients}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "15px",
                            color: "#3A74B6",
                            strokeWidth: "50",
                            stroke: "#3A74B6"
                          }}
                        />
                      }
                    />
                  )}
                </div>
                <div style={{ position: "absolute", top: "35%", right: "5px" }}>
                  {index !== currentlySelectedData?.images?.length - 1 && (
                    <Button
                      type="ghost"
                      size="small"
                      style={{
                        border: "none",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        borderRadius: "5px",
                      }}
                      onClick={() => ref.current.next()}
                      icon={
                        <RightOutlined
                          className={styles.hoverClients}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "15px",
                            color: "#3A74B6",
                            strokeWidth: "50",
                            stroke: "#3A74B6"
                          }}
                        />
                      }
                    />
                  )}
                </div>
                {currentlySelectedData?.isErts || currentlySelectedData?.isProperty ?(<>
                <div
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "0",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    padding: "2px",
                    borderTopLeftRadius: "5px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                    height: "30px",
                  }}
                  {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: (event) => {
                    event.stopPropagation();
                    showModal();
                  }})}
                >
                  <BiImageAdd
                    className={styles.hoverClients}
                    style={{
                      color: "#3A74B6",
                      fontSize: "25px",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    padding: "2px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <Dropdown
                    trigger={["click", "hover"]}
                    overlay={<MenuItem item={el?._id} />}
                  >
                    <MoreOutlined
                      className={styles.hoverClients}
                      key="ellipsis"
                      style={{
                        marginLeft: "5px",
                        color: "#3A74B6",
                        fontSize: "20px",
                        strokeWidth: "50",
                        stroke: "#3A74B6"
                      }}
                    />
                  </Dropdown>
                </div>
                </>):
                (<></>)}
              </div>
            ))}
          </Carousel>
        ) : (
          <>
            <div
              style={{ position: "relative", width: "100%"}}
            >
              <Image
                preview={false}
                width={"200px"}
                height={100}
                src={housePlaceHolder}
                alt="home"
                style={{ objectFit: "cover" }}
              />
             {currentlySelectedData && (currentlySelectedData.isErts || currentlySelectedData.isProperty) && (
              currentlySelectedData.propertyStatus !== "DRAFT" &&
              currentlySelectedData.propertyStatus !== "INPROGRESS" &&
              currentlySelectedData.propertyStatus !== "TERMINATED"
                ? (
                <div
                    style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    padding: "2px",
                    borderTopLeftRadius: "5px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                    height: "30px",
                    }}
                    {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: (event) => {
                      event.stopPropagation();
                      showModal();
                      }})}
                >
                    <BiImageAdd
                    className={styles.hoverClients}
                    style={{
                        color: "#2C59A9",
                        fontSize: "25px",
                    }}
                    />
                </div>
                )
                : null
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { PropertyImgs };
