import React from 'react'
import { DocumentSign } from './DocumentSign'
import { EmailFile } from './EmailFile'
import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { Help } from '../App/components/Help'
import styles from "./styles/ClientScreen.module.css"
const DefaultScreen = () => {

  return (
    <div  style={{width:"100%"}}  >
    <div style={{display:"flex",width:"100%",fontSize:"15px"}}>
    <Tabs defaultActiveKey="1"  className={styles.responsivetabs} tabBarExtraContent={ <div style={{margin:"5px 5px 0 0"}}>
      <Help tabName="CLIENT_SCREEN"/>
    </div>}> 
      <TabPane tab={<div className={styles.tabname}>Document Signing</div>} key="1" style={{width:"100%",height:"100%",fontSize:"10px"}}>
      <DocumentSign  />
      </TabPane>
      <TabPane tab={<div className={styles.tabname}>Email & Files</div>}  key="2" style={{width:"100%",height:"100%"}}>
      <EmailFile/>
      </TabPane>
    </Tabs>
    </div>
     </div>
  )
}

export {DefaultScreen}
