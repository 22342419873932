import { Button, Card, Form, Row, Spin, Typography } from "antd";
import React from "react";
import styles from "./styles/ClientAuth.module.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientAuthHandle } from "./state/actions.js";
import { useHistory } from "react-router-dom";
import { decryptQuery } from "../Common/utils/extraFunctions.js";

const { Title, Text } = Typography;

export default function ClientAuth() {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const history = useHistory();
  const query = new URLSearchParams(search);
  const [urlId, setUrlId] = useState("");
  const [error, setErr] = useState("");

  const { setLoading, setError } = useSelector((state) => state.clientAuth);

  useEffect(() => {
    localStorage.removeItem("auth");
    const emailKey = decryptQuery(query.get("emailKey"));
    
    const urlId = decryptQuery(query.get("urlId"));
    const token = decryptQuery(query.get("token"));
    const documentId = decryptQuery(query.get("documentId"));
    
    if (urlId) {
      setUrlId(urlId);
    } else {
      setErr("Url ID is required");
    }

    if (emailKey) {
      if (emailKey.length < 5) {
        setErr("Invalide code!");
        return;
      }
      dispatch(clientAuthHandle.verifyOTP({ OTP: emailKey, urlId, token, documentId}, history));
    } else {
      setErr("Invalide code!");
    }
  }, []);

  useEffect(() => {
    setErr(setError);
  }, [setError]);

  return (
    <div className={styles.alignAdmin}>
      <Card className={styles.custForm}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <div className={styles.Loginlogo}>
            <img
              style={{ width: "52px", height: "auto" }}
              src={"https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-blue-text.png"}
              alt="Logo"
            />
          </div>
          <div>
            <h2 className={styles.custFormH2}>Verification</h2>
          </div>
          <div className={styles.center}>
            <Title type="secondary" level={5} style={{width: "500px", textAlign: "center"}}>
              Authorizing access to your account
            </Title>
          </div>
          {setLoading ? (
            <div className={styles.center}>
              <Spin size="large" />
            </div>
          ) : (
            <Form>
              {/* <Form.Item>
                <div className={styles.center}>
                  <OtpInput
                    numInputs={5}
                    value={otp}
                    // isInputNum={true}
                    onChange={(res) => {
                      setOtp(res);
                      if (error) {
                        setErr("");
                      }
                      if (setError) {
                        dispatch(clientAuthHandle.verifyOTPError(""));
                      }
                    }}
                    separator={<span style={{padding:"0px"}}>-</span>}
                    inputStyle={{
                      width: isMobile ? "55px" : "70px",
                      border: "1px solid #afaeae",
                      height: isMobile ? "40px" :"50px",
                    margin:"auto",
                    }}
                  />
                </div>
              </Form.Item> */}
              <Row justify="center">
                {error && (
                  <Text type="danger" style={{ marginTop: "10px" }}>
                    {error}
                  </Text>
                )}
              </Row>
              <Form.Item>
                <div className={styles.center} style={{ marginTop: "30px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.btnPrimary}
                    disabled={!urlId}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
        </div>
      </Card>
    </div>
  );
}
