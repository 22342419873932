/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PlusCircleFilled,ExclamationCircleOutlined, DeleteTwoTone,FileDoneOutlined,FileImageOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FaRegFilePdf } from 'react-icons/fa'
import { clientScreenHandle } from './state/actions';
import { Button, Input, Modal, Spin, Upload, message, Typography } from 'antd';
import { useRef } from 'react';
import confirm from 'antd/lib/modal/confirm';
import styles from "./styles/ClientScreen.module.css"
import { EmptyData } from '../Common/components/EmptyData';
import { useMediaQuery } from 'react-responsive';

const EmailFile = () => {
  const [isHovered, setIsHovered] = useState(null);
  const [isHovered2, setIsHovered2] = useState(null);
  const { emailFilesData,emailFilesLoading , propertUploadDocs,propertDocsLoading} = useSelector((state) => state.clientScreen);
  const { getUserDetailsData } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({maxWidth:420})
  const convertTimestampToMMDDYYYY = (timestamp) => {
    let date = moment(timestamp).format("MM/DD/YYYY");
    return date;
  }
  
  const convertTimestampTotime = (timestamp) => {

    const currentTime = moment(timestamp).format('h:mm A');
    return currentTime;
  }
  
  useEffect(() => {
    let data = {
      role: getUserDetailsData?.openFor,
      _id: getUserDetailsData?._id,
      ertsId:getUserDetailsData?.ertsId,
      offerId : getUserDetailsData?.offerId,
      thirdpartyIdDoc:getUserDetailsData?.thirdpartyId || getUserDetailsData?.thirdpartyIdDoc
    };
    dispatch(clientScreenHandle.EmailFileData( data));
  }, []);
  
useEffect(()=>{
  const params = {
    ...(getUserDetailsData?.offerId ? {offerId:getUserDetailsData?.offerId} : {}),
    ...(getUserDetailsData?._id ? {personId: getUserDetailsData?._id} : {}),
    ...(getUserDetailsData?.ertsId ? {ertsId: getUserDetailsData?.ertsId} : {}),
    ...(getUserDetailsData?._thirdpartyId ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyId} : {}),
    ...(getUserDetailsData?.thirdpartyIdDoc ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyIdDoc} : {}),
    openFor: getUserDetailsData?.openFor
  }

dispatch(clientScreenHandle.getPropertyDocsFun(params))

},[])

  const uploadFile = useRef("");
  const [fileSizeOfDocUpload,setFileSizeOfDocUpload] = useState("")
  const [showInputFileName,setShowInputFileName] = useState(false);
  const [fileNametext,setFileNametext] = useState("");
  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  // Handling PropertyDocs
  let handleCustome = (event) => {
    setShowInputFileName(true)
    setFileNametext(event?.file?.name)
    let fileSize = bytesToSize(event?.file?.size)
    setFileSizeOfDocUpload(fileSize)
    const formData = new FormData();
    formData.append('file', event?.file);
    if(event?.file?.name?.includes(".pdf")){
      formData.append('fileType', "PDF");
    }
    else{
      formData.append('fileType', "IMAGE");
    }
    uploadFile.current = (formData);
 
  };

  const uploadTemp = () => {
    setShowInputFileName(false)
    uploadFile.current.append("filename",fileNametext);
    if(getUserDetailsData?.offerId) uploadFile.current.append("offerId", getUserDetailsData?.offerId);
    if(getUserDetailsData?._id) uploadFile.current.append("personId", getUserDetailsData?._id);
    if(getUserDetailsData?.ertsId) uploadFile.current.append("ertsId", getUserDetailsData?.ertsId);
    if(getUserDetailsData?.thirdpartyId) uploadFile.current.append("thirdpartyIdDoc", getUserDetailsData?.thirdpartyId);
    if(getUserDetailsData?.thirdpartyIdDoc) uploadFile.current.append("thirdpartyIdDoc", getUserDetailsData?.thirdpartyIdDoc);
    
    if(getUserDetailsData?.openFor) uploadFile.current.append("openFor", getUserDetailsData?.openFor);
    const params = {
      ...(getUserDetailsData?.offerId ? {offerId:getUserDetailsData?.offerId} : {}),
      ...(getUserDetailsData?._id ? {personId: getUserDetailsData?._id} : {}),
      ...(getUserDetailsData?.ertsId ? {ertsId: getUserDetailsData?.ertsId} : {}),
      ...(getUserDetailsData?._thirdpartyId ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyId} : {}),
      ...(getUserDetailsData?.thirdpartyIdDoc ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyIdDoc} : {}),
      openFor: getUserDetailsData?.openFor
    }

    dispatch(clientScreenHandle.uploadPropertyDocs(uploadFile.current, params));
    setFileNametext("")
  }
  function beforeUpload(file) {
    let supportedFormats = file?.type
 
    if (supportedFormats?.includes("image")) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/avif" || file.type === "image/gif" || file.type === "image/bmp" || file.type === "image/tiff" || file.type === "image/svg";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }

    if (supportedFormats?.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
      }
      return isPdf && isLt2M;
    }
  }
  const handleCancelInputFileName = ()=>{
    setFileNametext("")
    setShowInputFileName(false)
    setFileSizeOfDocUpload("")
  }
  const handleCloseSetFileNameModalConfirm = (docId) => {
    confirm({
      title: `Are you sure you don't want to upload this document?`,
      // content: "Note: This action is irreversible. Deleting this document will permanently remove it from your account.", 
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleCancelInputFileName()
        // dispatch(propertyDataHandle.removePropertyDocsFun(docId));
      },
      onCancel() {

      },
    });
  };


  const handleDeleteConfirm = (docId) => {
    const params = {
      ...(getUserDetailsData?.offerId ? {offerId:getUserDetailsData?.offerId} : {}),
      ...(getUserDetailsData?.ertsId ? {ertsId:getUserDetailsData?.ertsId} : {}),
      ...(getUserDetailsData?._id ? {personId: getUserDetailsData?._id} : {}),
      ...(getUserDetailsData?._thirdpartyId ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyId} : {}),
      ...(getUserDetailsData?.thirdpartyIdDoc ? { thirdpartyIdDoc:getUserDetailsData?.thirdpartyIdDoc} : {}),
      openFor: getUserDetailsData?.openFor
    }
    confirm({
      title: `Are you sure you want to delete this file?`,
      content: "Note: This action is irreversible. Deleting this file will permanently remove it from your account.", 
      okText: "Yes",
      cancelText: "No",
      okType: "danger",
      icon: <ExclamationCircleOutlined style={{ color: "orange" }} />,
      onOk() {dispatch(clientScreenHandle.removePropertyDocsFun(docId, params));},
      onCancel() {},
    });
  };

  const handleShowPdfClick = (el)=>{
    let content =  new Uint8Array(el?.fileUrl?.data);
     const blob = new Blob([content], { type: "application/pdf" });
       const fileUrl = URL.createObjectURL(blob);
       window.open(fileUrl);
   }
  return (
    <>
    <div style={{ width: "100%", height: "calc(100vh - 10.1rem)"}}>
      
       
          <div style={{justifyContent:"center",display:"flex",alignItems:"center", fontWeight: "bold", width: "100%", fontSize:"18px",color: "Gray",height:"7%" }}>Emails from RE-Sure</div>
          
          <div style={{ display: "flex", width: "100%", backgroundColor: " #0078d4",height:"6%" ,color: "white",alignItems:"center" ,padding: "10px",position:'sticky' }}>
            <div className={styles.action2}>From</div>
            <div className={styles.action2}>Date/Time</div>
            <div className={styles.action3}>Contract Name</div>
          </div>
          {emailFilesLoading ?  <div style={{ width: "100%", fontSize: "15px", height: window.screen.width?"35%":"20%",paddingTop:"10%",textAlign:"center" }}><Spin/></div>:
          <div style={{ width: "100%", fontSize: "15px", height:"36%", color: "black", overflowY: "auto", }}>
          {emailFilesData? emailFilesData?.map((ele,index) => {
            const timestamp = ele.sentDateMili;
            const formattedDate = convertTimestampToMMDDYYYY(timestamp);
            const currentTime=convertTimestampTotime(timestamp)
            return (
              <div 
                 key={index}
                 style={{
                  display: "flex",
                  backgroundColor: isHovered===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  alignItems:"center"
                }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}>
                <div style={{ width: "25.33%", fontSize: "15px", padding: "5px", textAlign: isMobile ? "left" : "center" }}>{ele.sentBy}</div>
                <div style={{ width: "25.33%", textAlign: isMobile ? "left" : "center" }}>{formattedDate}{"  "}{currentTime}</div>
                <div style={{ width: "49.34%", textAlign: isMobile ? "right" : "center",marginLeft:"5px" }}>{ele.contractName}</div>
              </div>
            );
          }):<EmptyData/>}
        </div>}
        <hr style={{ color: "#0078d4" }} />
        <div style={{ justifyContent:"center",display:"flex",alignItems:"center", fontWeight: "bold", width: "100%", fontSize:"18px",color: "Gray",height:"7%" }}>
          <div><FileDoneOutlined size={25} style={{ position: "relative", fontSize: "25px", color: "#085191"}}  /></div>
          <div style={{ paddingLeft: "10px", paddingRight: "10px", color: "Gray",fontSize: "18px" }}>Files Upload</div>
          <Upload className="borderless-button" showUploadList={false} accept="image/*,.pdf" 
          customRequest={handleCustome} beforeUpload={beforeUpload}
           style={{background: "red", marginRight: "20px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", }}>
            
           <PlusCircleFilled size={25} style={{ position: "relative", fontSize: "25px", color: "#085191",cursor:"pointer"}} /></Upload>
        </div>
        <div style={{ display: "flex", width: "100%", backgroundColor: " #0078d4",height:"6%" ,color: "white",alignItems:"center" ,padding: "10px",position:'sticky' }}>
          <div className={styles.action3}>File Name</div>
          <div className={styles.action2}>Date/Time</div>
          <div className={styles.action2}>Delete</div>
        </div>
        {propertDocsLoading ?  <div style={{ width: "100%", fontSize: "15px", height: "36%",paddingTop:"10%",textAlign:"center" }}><Spin/></div>:
        <div style={{ width: "100%", fontSize: "15px", height: "34.5%", color: "black", overflowY: "auto",}}>
        {propertUploadDocs.length>0? propertUploadDocs?.map((ele,index) => {
          const timestamp = ele.createdDateMili;
          const formattedDate = convertTimestampToMMDDYYYY(timestamp);
          const currentTime=convertTimestampTotime(timestamp)
          return (
            <div  
              key={index}
                  style={{
                  display: "flex",
                  backgroundColor: isHovered2===index ? "rgb(132, 187, 238)" : 'transparent',
                  margin: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  alignItems:"center"
                }}
                onMouseEnter={() => setIsHovered2(index)}
                onMouseLeave={() => setIsHovered2(null)}
                
                >
           <a target ="blank" href={ele.fileUrl} style={{width: "49.34%",color:"black"}}>
           <div style={{ width: "100%", padding: "5px",display:"flex",textAlign:"center"}}>
            <div style={{marginRight:"10px",color:"#0078d4"}} >{ele.fileType==="IMAGE"?
            <FileImageOutlined style={{fontSize:"20px",color:"red"}}/>: <FaRegFilePdf style={{fontSize:"20px",color:"red"}}/>}
            </div>
            <Typography.Text style={{width:"100%",textAlign:"center"}}>{ele.fileName}</Typography.Text>
            </div></a>
            <div style={{ width: "25.33%",textAlign: "center" }}><a target ="blank" href={ele.fileUrl} style={{color:"black",textAlign:"center"}}>{formattedDate}{"  "}{currentTime}</a></div>
            <div style={{ width: "25.33%" ,textAlign: "center",cursor:"pointer",fontSize:"20px"}} onClick={()=>handleDeleteConfirm(ele._id)}><DeleteTwoTone/></div>
          </div>)}):<EmptyData/>}
        </div>}
     
    </div>
    
      {/* Modal to add Input file Name */}
      <Modal   title={<div style={{ display: "flex", justifyContent: "center", color: "grey", fontWeight: "bold", fontSize: "20px" }}>Enter File Name</div>}
      footer={<Button type="primary" onClick={uploadTemp} disabled={fileNametext?.length > 0 ? false : true}>Upload</Button>}
      open={showInputFileName}  
      onCancel={handleCloseSetFileNameModalConfirm}>
     <div >
     <p><span style={{fontWeight:"bold"}}>File Name :</span> {fileNametext}</p>
     <p><span style={{fontWeight:"bold"}}>File Size :</span> {fileSizeOfDocUpload}</p>
     <Input value={showInputFileName ? fileNametext : ""} defaultValue={""} placeholder="Enter File Name" onChange={(e)=>{setFileNametext(e.target.value)}}/>
     </div>
     </Modal>
    </>
  );
}

export { EmailFile };
